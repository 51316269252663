import React, { useEffect } from "react";
import { User } from "oidc-client-ts";
import { startSignIn, startSignOut, storeUser } from "../../utilities/Auth";

export default function Auth() {
  const isSignOut = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const signOutParam = urlParams.get("signout");

    return typeof signOutParam === "string" && signOutParam.toLowerCase() === "true";
  };

  const fetchUser = () =>
    new Promise<User>((resolve) => {
      Office.context.ui.addHandlerAsync(
        Office.EventType.DialogParentMessageReceived,
        (result: Office.DialogParentMessageReceivedEventArgs) => {
          const user = User.fromStorageString(result.message);
          resolve(user);
        }
      );
      Office.context.ui.messageParent("get-user");
    });

  useEffect(() => {
    if (isSignOut()) {
      fetchUser()
        // This is to synchronize the child window userManager session state with the parent.
        .then((user: User) => storeUser(user))
        .then(() => {
          Office.context.ui.messageParent("starting-signout");
          void startSignOut();
        });
    } else {
      void startSignIn();
    }
  });

  return <div></div>;
}
